export const validateClearingNumber = (value, constraint) => {
    if(!value) return {status: true}
    let parts = constraint ? constraint.split("~") : []
    let rule = parts.length > 1 ? parts[1] : null
    let message = parts[0]
    if(rule){
        let regex = new RegExp(rule)
        if(regex.exec(value)){
            return {status: true}
        }
    }
    return { status: false, key: "validationPlaceholder", args: `${message}`}    
}
export const validateAccountNumber = (value, constraint) => {
    if(!value) return {status: true}
    let parts = constraint ? constraint.split("~") : []
    let rule = parts.length > 1 ? parts[1] : null
    let message = parts[0]
    if(rule){
        let regex = new RegExp(rule)
        if(regex.exec(value)){
            return {status: true}
        }
    }
    return { status: false, key: "validationPlaceholder", args: `${message}`}    
}

export const validateEmail = (value, constraint) => {

    if((value && validEmail(value)) || !value || value.length == 0) {
        return { status: true }
    }

    return {
        status: false,
        key: "validation.email"
    }

}

function validEmail(email) {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return re.test(String(email).toLowerCase())
}