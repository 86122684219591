import Vue from 'vue'
import App from './page/App.vue'
import { validateClearingNumber} from './validations'
import { validateAccountNumber} from './validations'
import { validateEmail } from './validations'


import veform from '@verified/veform'
Vue.config.productionTip = false

import "./page/style.scss"

veform.addCustomValidation('clearing-number', validateClearingNumber)
veform.addCustomValidation('account-number', validateAccountNumber)
veform.addCustomValidation('optional-email', validateEmail)

Vue.use(veform)

new Vue({
    render: h => h(App),
}).$mount('#app')
