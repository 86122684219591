import { auth, envelopes, bisnode } from "@verified/libvf";
import axios from 'axios'
import veform from '@verified/veform'

export default {
  name: "app",
  data() {
    return {
			lang: "sv_SE",
			loading: true,
			sent: false,
			banks: null,
			translations: null,
			redirectSign: "",
			orgNotFound: false,
			ud: {
				_isForwarded: false,
				fileName: null,
				translations: [],
				lang: "sv_SE",
				payer:{
					name: null,
					firstName: null,
					lastName: null,
					ssn: null,
					address: null,
					zip: null,
					city: null,
					bank: null,
					accountNr: null,
					clearingNr: null,
					orgNr: null,
				},
				invoiceEmail: null,
				customerNr: null,
				selectOption: {
					appliesToAll: false,
					appliesToSpecific: false
				}
			}
		}
	},
	methods: {
		__(key) {
			if(this.translations[key])
				return this.translations[key][this.$root.locale]
		},
		getUrlParameter(param, dummyPath) {
			var sPageURL = dummyPath || window.location.search.substring(1),
				sURLVariables = sPageURL.split(/[&||?]/),
				res;
			for (var i = 0; i < sURLVariables.length; i += 1) {
				var paramName = sURLVariables[i],
					sParameterName = (paramName || '').split('=');
				if (sParameterName[0] === param) {
					res = sParameterName[1];
				}
			}
			return res;
		},
		langTo(lang) {
			this.$root.locale = lang
			this.lang = this.ud.lang =lang;
            this.$forceUpdate()
            this.$emit("language",this.$root.locale)
		},
		validateForm() {
			var valid = this.$refs['form1'].validate()
			if(!valid){
				let elements = this.$refs['form1'].$refs.form.querySelectorAll(':invalid')
				if(elements && elements.length > 0 && elements.item(0)){
					const element = elements.item(0)
					element.scrollIntoView({
						behavior: "smooth",
						block: "center",
						inline: "nearest"
					});
					setTimeout(() => {element.focus()}, 300)
				}
			  }
			  return valid
		},
		async fetchBisnode(orgNr){
			if(orgNr && orgNr.length === 10){
				this.busy = true
				this.loading = true
				this.orgNotFound = false
				try{
					let company = await bisnode.sweden.getCompanyInfo(orgNr)
					if(company){
						this.ud.payer.name = company.companyName
						this.ud.payer.address = company.postalAddress
						this.ud.payer.city = company.city
						this.ud.payer.zip = company.postalCode
					}
				}catch(ex){
					this.orgNotFound = true
					this.ud.payer.name = null
					this.ud.payer.address = null
					this.ud.payer.city = null
					this.ud.payer.zip = null
					console.log(ex)
				}
				this.busy = false
				this.loading = false
			}else{
				return
			}
		},

		selectBanks(){
			let retVal = []
			if(this.banks){
				retVal = Object.keys(this.banks)
			}
			return retVal;
		},
		getClearingNoMask(){
			let retVal = '####'
			if(this.ud.payer.bank){
				let bank = this.banks[this.ud.payer.bank]
				retVal = `${bank.clearMask}`
			}
			return retVal
		},
		getClearingNoRule(){
			let retVal = 'required'
			if(this.ud.payer.bank){
				let bank = this.banks[this.ud.payer.bank]
				retVal += `|clearing-number:${bank.clearNoFormat}~${bank.clearPattern}`
			}
			return retVal
		},
		getAccountNoRule(){
			let retVal = 'required'
			if(this.ud.payer.bank){
				let bank = this.banks[this.ud.payer.bank]
				retVal += `|account-number:${bank.accNoValidation}~${bank.accPattern}`
			}
			return retVal
		},
		bankSelected(val){
		},
		async submit() {
			if(!this.validateForm('form1')){
				this.isFormSubmit = true
				return
			}

			this.loading = true
			let envelope = null
			let self = this

			this.ud.fileName = this.ud.fileName.replace("%name%", this.ud.payer.name)
			this.ud.fileName = this.ud.fileName.replace("%firstname%", this.ud.payer.firstName)
			this.ud.fileName = this.ud.fileName.replace("%lastname%", this.ud.payer.lastName)
			this.ud.fileName = this.ud.fileName.replace("%ssn%", this.ud.payer.ssn)
			this.ud.fileName = this.ud.fileName.replace("%orgnr%", this.ud.payer.orgNr)

			this.ud.emailSubject = this.__('confirmationEmailSubject')
			this.ud.emailBodyText = this.__('confirmationEmailBodyText')

			let recipients = [{
				"givenName": this.ud.payer.firstName,
				"familyName": this.ud.payer.lastName,
				"email": this.ud.payer.email,
				"language": "sv_SE",
				"signingMethod": self.signingMethod || "bankid-se",
				"order": 1,
				"role": {
					"action": "sign",
					"label": "Signer",
					"name": "signer"
				}
			}]

			envelopes.create('martinservera-direct-debit').then(function (res) {
				envelope = res;
			}).then(function () {
				return envelope.getBearerToken('/flows/simple-public-flow');
			}).then(function (token) {
				return auth.setToken(token);
			}).then(function (env) {
				return envelope.reflect();
			}).then(function () {
				envelope.firstTemplate().setUserData(self.ud)
			}).then(function () {
				let recs = [];
				for (let res in recipients)
					recs.push(envelope.addRecipient(recipients[res]));
				return Promise.all(recs);
			}).then(function () {
				return envelope.firstDocument().put({ name: self.ud.fileName });
			}).then(function () {
				return envelope.publish();
			}).then(function () {
				return envelope.getSignToken("/flows/simple-public-flow");
			}).then(function (token) {
				let signUrl = "https://app.verified.eu/#/sign" + envelope.data.uid + "?access_token=" + token + "&lang=" + self.lang + "&redirect_to=" + encodeURIComponent(self.redirectSign);
				console.log("Got sign URL:", signUrl);
				window.location.href = signUrl;
			}).catch(function (err) {
				console.log("ERROR", err);
				this.busy = false
				this.loading = false
				throw err;
			});

		}
	},
	computed: {
	},
	watch: {
		lang: function(newVal) {
            if(newVal) {
                this.langTo(newVal)
            }
		},
		"ud.payer.orgNr": function(newVal, oldVal){
			this.orgNotFound = false
			if(newVal && newVal.length === 11){
				this.fetchBisnode(newVal.replace(/[^\d]/g, ""))
			}
		}
	},
	async created() {

		const [translations,config, banks] = await Promise.all([
			axios.get('https://sheets.web-services.verified.eu/martinservera-direct-debit/translations'),
			axios.get('https://sheets.web-services.verified.eu/martinservera-direct-debit/config'),
			axios.get('https://sheets.web-services.verified.eu/martinservera-direct-debit/banks')
		])
		var source    = this.getUrlParameter('source')||'value';

		// This combines veform's default translations with the ones from sheets
		// duplicates are overwritten by the sheet
		this.mergeLocale(translations.data)

		//For bankAccount and clearingNumber validation
		let validationPlaceholder = {
			"validationPlaceholder":{
				"sv_SE":"%s",
				"en_EN":"%s"
			}
		}
		this.mergeLocale(validationPlaceholder)
		this.ud.translations = this.translations = translations.data
		this.config = config.data
		document.title = this.config.title[source];
		this.ud.fileName = this.config.fileName[source];
		this.signingMethod = this.config.signingMethod[source] || "bankid-se"
		this.redirectSign = this.config.redirectSign[source]

		let cover = document.getElementById("cover");
		if(this.config){
			cover.style.setProperty("--bg-image", `url(${this.config.background[source]})`)//backgroundImage = `url(${this.config.background[source]})`
		}

		this.banks = banks.data
		// This combines veform's default translations with the ones from sheets
		// duplicates are overwritten by the sheet
		let clearingNoValidations = {"validation.clearingnumber":{}}
		let accountNoValidations = {"validation.accountnumber":{}}
		for (const key in this.banks) {
			if (this.banks.hasOwnProperty(key)) {
				const bank = this.banks[key];
				clearingNoValidations["validation.clearingnumber."+bank.text] = {}
				clearingNoValidations["validation.clearingnumber."+bank.text][this.lang] = bank.clearNoFormat
				accountNoValidations["validation.accountnumber."+bank.text]= {}
				accountNoValidations["validation.accountnumber."+bank.text][this.lang] = bank.accNoFormat
			}
		}
		this.mergeLocale(clearingNoValidations);
		this.mergeLocale(accountNoValidations);


		// Set language to use
		var lang = this.getUrlParameter('lang')
		if(lang){
			lang = /en/.test(lang) ? 'en_EN' : 'sv_SE'
		}else{
			lang = 'sv_SE'
		}
		this.langTo(lang)
		this.setIso(this.lang)

		let authOpts = {token:this.config.token[source], namespace:this.config.namespace[source]}
		auth.authenticate(authOpts)

		this.loading = false;
	},
	components: {
	},
}